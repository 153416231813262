* {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  overscroll-behavior: none;
}

body.loading::before {
  width: 0%;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

body::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  @apply bg-red-700 opacity-75 transition-all duration-500;
}

body {
  overflow: hidden;
  overscroll-behavior-y: none;
}

body.loading .LoadingCard {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.gradient-red {
  background: rgb(142,8,17);
  background: linear-gradient(35deg, rgba(142,8,17,1) 0%, rgba(175,42,51,1) 100%);
}

.login-box .logo-box img {
  height: 150px;
}

.is-invalid-input {
  @apply border-red-500
}

.page-container {
  overflow-y: scroll;
  overscroll-behavior: none;
  max-height: calc(100vh - 7rem) !important;
}