@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Comfortaa';
  font-weight: 300;
  src: url(assets/fonts/Comfortaa-Light.ttf) format("truetype");
}

@font-face {
  font-family: 'Comfortaa';
  font-weight: 400;
  src: url(assets/fonts/Comfortaa-Regular.ttf) format("truetype");
}

@font-face {
  font-family: 'Comfortaa';
  font-weight: 500;
  src: url(assets/fonts/Comfortaa-Medium.ttf) format("truetype");
}

@font-face {
  font-family: 'Comfortaa';
  font-weight: 600;
  src: url(assets/fonts/Comfortaa-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: 'Comfortaa';
  font-weight: 700;
  src: url(assets/fonts/Comfortaa-Bold.ttf) format("truetype");
}

::selection {
  @apply bg-red-300
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-gray-100
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-300
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#root {
  display: flex;
  font-family: 'Comfortaa';
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
}

@layer components {
  .base-button {
      @apply w-24 py-3 bg-red-500 text-white font-bold rounded transition-all hover:scale-95;
  }

  input[type="text"].textbox,
  input[type="password"].textbox,
  input[type="email"].textbox,
  input[type="tel"].textbox,
  input[type="number"].textbox,
  input[type="date"].textbox {
    @apply px-3 py-2 border w-full outline-none rounded-[12px] transition-colors ease-in-out active:border-red-600 focus:border-red-600;
  }

  select {
    @apply px-3 py-2 border outline-none rounded-[12px] transition-colors ease-in-out appearance-none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 1rem center;
    background-size: 1em;
  }

  option {
    @apply p-2
  }

  textarea {
    @apply px-3 py-2 border w-full outline-none rounded-[12px] transition-colors ease-in-out active:border-red-600 focus:border-red-600;
  }

  input[type="button"] {
    @apply bg-red-500;
  }

  
  button.primary {
    @apply text-white mr-auto px-8 py-3 rounded-[12px] border-2 border-red-700 bg-red-700 hover:bg-transparent hover:text-red-700 hover:scale-90 transition-all;
  }


  a {
    @apply text-red-600 cursor-pointer hover:text-black transition-colors ease-in-out;
  }

  .pageTitle {
    @apply text-3xl my-auto
  }

  .sectionTitle {
    @apply text-xl mb-3
  }

  .nav-items {
    @apply text-center
  }

  .nav-link {
    @apply flex flex-row gap-4 text-lg m-2 p-4 rounded-[12px] text-black hover:bg-gray-200
  }

  .nav-link svg {
    @apply ml-auto my-auto
  }

  .nav-link span {
    @apply mr-auto font-bold text-base my-auto
  }
}